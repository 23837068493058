import { SiteStatus, TrackingState } from '@domain/graphql.types';
import type { LayerProps } from 'react-map-gl/mapbox';

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'all_sites',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#6B63B7',
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'all_sites',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#FFFFFF',
  },
};

export const comingSoonPointLayer: LayerProps = {
  id: 'coming-soon-point',
  type: 'symbol',
  source: 'all_sites',
  filter: ['all', ['!has', 'point_count'], ['==', 'status', SiteStatus.ComingSoon], ['==', 'includedInReport', true]],
  layout: {
    'icon-image': 'coming-soon',
  },
};

export const comingSoonNotIncludedPointLayer: LayerProps = {
  id: 'coming-soon-not-included-point',
  type: 'symbol',
  source: 'all_sites',
  filter: ['all', ['!has', 'point_count'], ['==', 'status', SiteStatus.ComingSoon], ['==', 'includedInReport', false]],
  layout: {
    'icon-image': 'coming-soon-not-included',
  },
};

export const trackedPointLayer: LayerProps = {
  id: 'tracked-point',
  type: 'symbol',
  source: 'all_sites',
  filter: ['all', ['!has', 'point_count'], ['==', 'trackingState', TrackingState.Tracked], ['==', 'includedInReport', true]],
  layout: {
    'icon-image': 'tracked',
  },
};

export const trackedNotIncludedPointLayer: LayerProps = {
  id: 'tracked-not-included-point',
  type: 'symbol',
  source: 'all_sites',
  filter: ['all', ['!has', 'point_count'], ['==', 'trackingState', TrackingState.Tracked], ['==', 'includedInReport', false]],
  layout: {
    'icon-image': 'tracked-not-included',
  },
};

export const untrackedPointLayer: LayerProps = {
  id: 'untracked-point',
  type: 'symbol',
  source: 'all_sites',
  filter: ['all', ['!has', 'point_count'], ['==', 'trackingState', TrackingState.NotTracked], ['==', 'includedInReport', true]],
  layout: {
    'icon-image': 'untracked',
  },
};

export const untrackedNotIncludedPointLayer: LayerProps = {
  id: 'untracked-not-included-point',
  type: 'symbol',
  source: 'all_sites',
  filter: ['all', ['!has', 'point_count'], ['==', 'trackingState', TrackingState.NotTracked], ['==', 'includedInReport', false]],
  layout: {
    'icon-image': 'untracked-not-included',
  },
};
