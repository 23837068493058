import { PrivateLayout } from '@application/components';
import OnboardingLayout from '@application/components/layouts/OnboardingLayout';
import { ErrorBoundary } from '@application/errors';
import { AuthGuard, CompletedOnboardingGuard, NoAuthGuard, OnboardingGuard, PayingCustomerGuard, RoleGuard, UserGuard } from '@application/guards';
import { Routes } from '@application/routes';
import {
  BulkSearch,
  Dashboard,
  GettingStarted,
  Home,
  OnboardingForm,
  Reports,
  SiteLookup,
  Tenant,
  TenantInvitations,
  TenantSubscription,
  TenantUsers,
} from '@application/views';
import { UserRole } from '@domain/graphql.types';
import * as Sentry from '@sentry/react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router';

const ONBOARDING_ROUTES = {
  [Routes.ONBOARDING]: <OnboardingForm />,
};

const PRIVATE_ROUTES = {
  [Routes.DASHBOARD]: <Dashboard />,
  [Routes.GETTING_STARTED]: <GettingStarted />,
  [Routes.REPORTS]: <Reports />,
  [Routes.SITE_LOOKUP]: <SiteLookup />,
};

const PUBLIC_ROUTES = {
  [Routes.HOME]: <Home />,
};

const Router = () => {
  const router = createBrowserRouter([
    {
      element: (
        <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
          <Outlet />
        </Sentry.ErrorBoundary>
      ),
      children: [
        {
          element: (
            <NoAuthGuard>
              <Outlet />
            </NoAuthGuard>
          ),
          children: Object.entries(PUBLIC_ROUTES).map(([path, element]) => ({
            path,
            element,
          })),
        },
        {
          element: (
            <AuthGuard>
              <UserGuard>
                <OnboardingGuard>
                  <OnboardingLayout>
                    <Outlet />
                  </OnboardingLayout>
                </OnboardingGuard>
              </UserGuard>
            </AuthGuard>
          ),
          children: Object.entries(ONBOARDING_ROUTES).map(([path, element]) => ({
            path,
            element,
          })),
        },
        {
          element: (
            <AuthGuard>
              <UserGuard>
                <CompletedOnboardingGuard>
                  <PrivateLayout>
                    <Outlet />
                  </PrivateLayout>
                </CompletedOnboardingGuard>
              </UserGuard>
            </AuthGuard>
          ),
          children: [
            ...Object.entries(PRIVATE_ROUTES).map(([path, element]) => ({
              path,
              element,
            })),
            {
              path: Routes.BULK_SEARCH,
              element: (
                <PayingCustomerGuard>
                  <BulkSearch />
                </PayingCustomerGuard>
              ),
            },
            {
              path: Routes.TENANT,
              element: (
                <RoleGuard authorizedRoles={[UserRole.Admin, UserRole.Owner]}>
                  <Tenant />
                </RoleGuard>
              ),
              children: [
                {
                  path: Routes.TENANT,
                  element: <Navigate to={Routes.TENANT_USERS} replace />,
                },
                {
                  path: Routes.TENANT_USERS,
                  element: <TenantUsers />,
                },
                {
                  path: Routes.TENANT_INVITATIONS,
                  element: <TenantInvitations />,
                },
                {
                  path: Routes.TENANT_SUBSCRIPTION,
                  element: (
                    <RoleGuard authorizedRoles={[UserRole.Owner]}>
                      <TenantSubscription />
                    </RoleGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to={Routes.DASHBOARD} replace />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
