import { BulkSearchAddressItem, GeoCoordinates } from '@domain/graphql.types';
import { accessToken, mapbox } from '@infrastructure/mapbox';
import { useEffect, useMemo, useRef } from 'react';
import { MapRef } from 'react-map-gl/mapbox';
import { FileState } from '../hooks/useBulkSearch';
import { useSitesRequest } from '@domain/index';
import { feature, featureCollection } from '@turf/turf';

const unitedStatesCenter: GeoCoordinates = {
  longitude: -98,
  latitude: 39,
};
const unitedStatesOnlyZoom = 4.75;

type Props = {
  addresses: readonly BulkSearchAddressItem[];
  fileState: FileState;
};

const useBulkMap = ({ addresses, fileState }: Props) => {
  const mapRef = useRef<MapRef>(null);

  const { data } = useSitesRequest();

  const allSites = useMemo(() => {
    const features =
      data?.map(({ coordinates, includedInReport, trackingState, status }) =>
        feature({ type: 'Point', coordinates: [coordinates.longitude, coordinates.latitude] }, { includedInReport, trackingState, status }),
      ) || [];

    return featureCollection(features);
  }, [data]);

  useEffect(() => {
    if (!addresses?.length || fileState === FileState.IDLE) {
      return;
    }

    const firstCoordinates: [number, number] = [addresses[0].coordinates.longitude, addresses[0].coordinates.latitude];

    const bounds = addresses.reduce(
      (currentBounds, address) => currentBounds.extend([address.coordinates.longitude, address.coordinates.latitude]),
      new mapbox.LngLatBounds(firstCoordinates, firstCoordinates),
    );

    mapRef.current?.fitBounds(bounds, { padding: 200 });
  }, [addresses, fileState]);

  return {
    refs: {
      map: mapRef,
    },
    state: {
      allSites,
      mapCenter: unitedStatesCenter,
      mapZoom: unitedStatesOnlyZoom,
    },
    map: {
      accessToken,
      style: import.meta.env.VITE_REACT_APP_MAP_BOX_MAP_STYLE,
    },
  };
};

export default useBulkMap;
